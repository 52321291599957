exports.components = {
  "component---src-pages-aksepter-tsx": () => import("./../../../src/pages/aksepter.tsx" /* webpackChunkName: "component---src-pages-aksepter-tsx" */),
  "component---src-pages-embeded-aksepter-tsx": () => import("./../../../src/pages/embeded/aksepter.tsx" /* webpackChunkName: "component---src-pages-embeded-aksepter-tsx" */),
  "component---src-pages-embeded-feil-tsx": () => import("./../../../src/pages/embeded/feil.tsx" /* webpackChunkName: "component---src-pages-embeded-feil-tsx" */),
  "component---src-pages-embeded-identifiser-tsx": () => import("./../../../src/pages/embeded/identifiser.tsx" /* webpackChunkName: "component---src-pages-embeded-identifiser-tsx" */),
  "component---src-pages-embeded-index-tsx": () => import("./../../../src/pages/embeded/index.tsx" /* webpackChunkName: "component---src-pages-embeded-index-tsx" */),
  "component---src-pages-embeded-oida-tsx": () => import("./../../../src/pages/embeded/oida.tsx" /* webpackChunkName: "component---src-pages-embeded-oida-tsx" */),
  "component---src-pages-embeded-status-tsx": () => import("./../../../src/pages/embeded/status.tsx" /* webpackChunkName: "component---src-pages-embeded-status-tsx" */),
  "component---src-pages-embeded-valider-tsx": () => import("./../../../src/pages/embeded/valider.tsx" /* webpackChunkName: "component---src-pages-embeded-valider-tsx" */),
  "component---src-pages-embeded-velg-tsx": () => import("./../../../src/pages/embeded/velg.tsx" /* webpackChunkName: "component---src-pages-embeded-velg-tsx" */),
  "component---src-pages-feil-tsx": () => import("./../../../src/pages/feil.tsx" /* webpackChunkName: "component---src-pages-feil-tsx" */),
  "component---src-pages-identification-done-tsx": () => import("./../../../src/pages/identification/done.tsx" /* webpackChunkName: "component---src-pages-identification-done-tsx" */),
  "component---src-pages-identifiser-tsx": () => import("./../../../src/pages/identifiser.tsx" /* webpackChunkName: "component---src-pages-identifiser-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oida-tsx": () => import("./../../../src/pages/oida.tsx" /* webpackChunkName: "component---src-pages-oida-tsx" */),
  "component---src-pages-personvern-tsx": () => import("./../../../src/pages/personvern.tsx" /* webpackChunkName: "component---src-pages-personvern-tsx" */),
  "component---src-pages-status-tsx": () => import("./../../../src/pages/status.tsx" /* webpackChunkName: "component---src-pages-status-tsx" */),
  "component---src-pages-valider-tsx": () => import("./../../../src/pages/valider.tsx" /* webpackChunkName: "component---src-pages-valider-tsx" */),
  "component---src-pages-velg-tsx": () => import("./../../../src/pages/velg.tsx" /* webpackChunkName: "component---src-pages-velg-tsx" */)
}

